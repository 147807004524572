


























import { CurrencyCodeType } from '@glittr/frontend-core/src/plugins/localization/currencyCodes';
import Vue from 'vue';
import BasketItemResourceViewModel from '@/src/services/viewModel/resource/BasketItemResourceViewModel';
import CreateSubscriptionContractRequestViewModel from '@/src/services/viewModel/resource/CreateSubscriptionContractRequestViewModel';
import AddressModel from '../../../../../services/viewModel/resource/ContactAddressResourceViewModel';

export default Vue.extend({
  hasBackButton: true,
  auth: true,
  props: {
    value: { type: Object, default: undefined },
  },
  data: () => ({
    isArticleDataLoading: false,
    billingAddress: new AddressModel(),
  }),
  computed: {
    innerValue: {
      get(): CreateSubscriptionContractRequestViewModel {
        return this.value ?? {};
      },
      set(value: CreateSubscriptionContractRequestViewModel) {
        this.$emit('input', value);
      },
    },
    subscriptionDisplay(): string | undefined {
      const [subscription] = this.innerValue.orderBasket.items ?? [];
      if (subscription) {
        const currency = subscription.currency as CurrencyCodeType;
        const price = this.$format.currency(subscription.unitPrice!, currency);
        const { caption } = subscription;
        return `${caption}: ${price}`;
      }
      return undefined;
    },
  },
  async mounted() {
    await this.populateMembershipData();
    await this.populateArticleData();
    const response = await this.$service.api.me.getMe();
    this.innerValue.billingContact.address.eMail = response.email;
  },
  methods: {
    async populateMembershipData() {

      // TODO: Prefill data, depends on use case

      // try {
      //   const myMembership = await this.$service.api.memberships.getMyMembership();
      //   if (myMembership) {
      //     this.innerValue.billingContact.address.fromModel(myMembership.memberContact?.address);
      //     this.innerValue.billingContact.customerNumber = undefined;
      //     this.innerValue.billingContact.contactId = undefined;
      //     this.innerValue.billingContact.parentContactId = undefined;
      //   }
      // } catch (error) {
      //   // ...
      // }
    },
    async populateArticleData() {
      this.isArticleDataLoading = true;
      const response = await this.$service.api.subscriptions.getGastroProfessionalSubscriptionTypes();
      const [subscriptionArticle] = response.items;
      const basketItem = new BasketItemResourceViewModel().fromModel(subscriptionArticle);
      basketItem.caption = subscriptionArticle.name;
      basketItem.quantity = 1;
      this.innerValue.subscriptionTemplateNumber = subscriptionArticle.subscriptionTemplateNumber!;
      this.innerValue.orderBasket.items = [basketItem];
      this.innerValue.orderBasket.currency = basketItem.currency;
      this.isArticleDataLoading = false;
    },
  },
});
